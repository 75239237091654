






import Vue from 'vue'

export default Vue.extend({
	middleware: ['auth'],
	auth: 'guest',
	head(this: any) {
		return {
			title: `${this.$t('titles.login')} | WithIn`,
		}
	},
	mounted() {
		if (process.browser && this.$auth.loggedIn) {
			this.$router.push('/feed')
		}
	},
})
